
<div class="body">
    <div class="bg"></div>
    <div class="bg2"></div>
    <div class="bg3"></div>
    <div class="container">
        <div id="left">
            <h1>Perspectief</h1>
            <p>Sandbox voor CSS perspective & rotate uit proberen, de achtergrond is aan de hand van clip-path</p>
        </div>
        <div id="right">
            <ul>
                <li>
                    <img src="../../assets/appcoins.svg" alt="">
                    <span>
                        <strong>AppCoins</strong>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore, nobis.</p>
                    </span>
                </li>
                <li>
                    <img src="../../assets/ethos.svg" alt="">
                    <span>
                        <strong>Ethos</strong>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore, nobis.</p>
                    </span>
                </li>
                <li>
                    <img src="../../assets/iota.png" alt="">
                    <span>
                        <strong>Iota</strong>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore, nobis.</p>
                    </span>
                </li>
            </ul>
        </div>
    </div>
</div>