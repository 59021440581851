<!-- <div id="container">
  <div id="title">
    <p>Angular 2048</p>
  </div>
  <div id="content">
    <app-board></app-board>
  </div>
  <div id="footer">
    <p>Updates:</p><br>
    <ul>
      <li>07/11/20 - Touch support.</li>
      <li>31/10/20 - Update from Angular 2.4 to 10.2 & RxJS 5.1 to 6.6</li>
    </ul>
  </div>
</div> -->

<mat-card class="example-card">
  <mat-card-header>
    <mat-card-title>2048</mat-card-title>
  </mat-card-header>
  <app-board></app-board>
  <mat-card-content>
    <p>Updates:</p>
    <ul>
      <li>08/11/20 - Layout improvements.</li>
      <li>07/11/20 - Touch support.</li>
      <li>31/10/20 - Update from RxJS 5.1 to 6.6</li>
      <li>31/10/20 - Update from Angular 2.4 to 10.2</li>
    </ul>
  </mat-card-content>
  <!-- <mat-card-actions>
    <button mat-button>LIKE</button>
    <button mat-button>SHARE</button>
  </mat-card-actions> -->
</mat-card>