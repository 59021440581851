<div class="scene">
    <div class="floor"></div>
    <div class="cube">
        <div class="front"></div>
        <div class="back"></div>
        <div class="left"></div>
        <div class="right"></div>
        <div class="top">
            <div class="ballShadow"></div>
        </div>
        <div class="bottom"></div>
    </div>
    <div class="ball"></div>
</div>