import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TwentyFourtyEightModule } from './twentyfourtyeight/twentyfourtyeight.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {MatCardModule} from '@angular/material/card';
import {MatButtonModule} from '@angular/material/button';
import { HomeComponent } from './home/home.component';
import { PerspectiveComponent } from './perspective/perspective.component';
import { BallbounceComponent } from './ballbounce/ballbounce.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    PerspectiveComponent,
    BallbounceComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    TwentyFourtyEightModule,
    BrowserAnimationsModule,
    MatCardModule,
    MatButtonModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
