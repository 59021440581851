import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-twentyfourtyeight',
  templateUrl: './twentyfourtyeight.component.html',
  styleUrls: ['./twentyfourtyeight.component.css']
})
export class TwentyfourtyeightComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
