<div class="container">
  <mat-card class="example-card" >
    <a style="text-decoration: none">
        <mat-card-header>
            <mat-card-title>Welkom</mat-card-title>
            <mat-card-subtitle>intro</mat-card-subtitle>
          </mat-card-header>
          <mat-card-content>
            <p>
              Voorlopig kan u zich alvast amuseren met deze webversie van 2048. Kom af en toe is terugkijken of er iets nieuw is!
            </p>
          </mat-card-content>
    </a>
  </mat-card>

  <mat-card class="example-card">
    <a style="text-decoration: none"  routerLink="/2048" routerLinkActive="active">
        <mat-card-header>
            <div mat-card-avatar class="example-header-image"></div>
            <mat-card-title>2048</mat-card-title>
            <mat-card-subtitle>board game</mat-card-subtitle>
          </mat-card-header>
          <img mat-card-image src="../assets/card2048.png">
          <mat-card-content>
            <p>
              Voeg tegels met oplopende waarden samen tot er een tegel met een waarde van 2048 verschijnt.
            </p>
          </mat-card-content>
    </a>
  </mat-card>

  <mat-card class="example-card">
    <a style="text-decoration: none"  routerLink="/perspective" routerLinkActive="active">
        <mat-card-header>
            <mat-card-title>Perspectief</mat-card-title>
            <mat-card-subtitle>test</mat-card-subtitle>
          </mat-card-header>
          <img mat-card-image src="../assets/perspective.png">
          <mat-card-content>
            <p>
              Op deze pagina test ik een paar css eigenschappen zoals: perspective, rotate & clip-path.
            </p>
          </mat-card-content>
    </a>
  </mat-card>
</div>
