import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TwentyfourtyeightComponent } from './twentyfourtyeight/twentyfourtyeight/twentyfourtyeight.component';
import { HomeComponent } from './home/home.component';
import { PerspectiveComponent } from './perspective/perspective.component';
import { BallbounceComponent } from './ballbounce/ballbounce.component';

const routes: Routes = [
  { path: '', component: TwentyfourtyeightComponent},
  { path: '2048', component: TwentyfourtyeightComponent},
  { path: 'perspective', component: PerspectiveComponent },
  { path: 'home', component: BallbounceComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
