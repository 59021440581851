<div id="header">
    <p id="score">Score: {{score !== -1 ? score : 0}}</p> 
    <button id="new-game-btn" (click)="restart()">New game</button>
  </div>
  <div id="board">  
    <div id="game-over" *ngIf="gameOver">
      <div id="game-over-body">
        <h4 *ngIf="!completed">Game Over!</h4>
        <h4 *ngIf="completed">You win!</h4>
        <button (click)="restart()">Restart</button>
      </div>
    </div>
    <app-cell *ngFor="let cell of cells" [cell]="cell"></app-cell>
  </div>