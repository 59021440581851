import { Component, OnInit, HostListener } from '@angular/core';
import { KEY_MAP } from '../constants/key-map';
import { GameService } from '../game.service';
import { Cell } from '../cell.model';

@Component({
  selector: 'app-board',
  templateUrl: './board.component.html',
  styleUrls: ['./board.component.css']
})
export class BoardComponent implements OnInit {

  cells: Cell[];
  gameOver: boolean = false;
  score: number = -1;
  completed: boolean = false;

  defaultTouch = { x: 0, y: 0, time: 0 };

  @HostListener('window:keydown', ['$event']) handleKeyboardEvent(event: KeyboardEvent) {
    let moveSuccessful = false;
    const direction = KEY_MAP[event.keyCode];
    console.log(direction);
    if (this.gameOver || direction === undefined) return;
    this.game.move(direction).subscribe((mergeScore: number) => {
      moveSuccessful = moveSuccessful || this.score < mergeScore;
    }, console.error, () => {
      if (this.gameOver) return;
      if (moveSuccessful) this.game.randomize();
      this.score = this.game.score;
      this.gameOver = this.game.isGameOver;
    });
  }

  @HostListener('touchstart', ['$event'])
  @HostListener('touchend',['$event'])
  @HostListener('touchcancel',['$event'])
  handleTouch(event) {
    let moveSuccessful = false;

    if (this.gameOver) return;

    let touch = event.touches[0]  || event.changedTouches[0];
    
    if (event.type === 'touchstart') {
      this.defaultTouch.x = touch.pageX;
      this.defaultTouch.y = touch.pageY;
      this.defaultTouch.time = event.timeStamp;
    } else if (event.type === 'touchend') {
      let deltaX = touch.pageX - this.defaultTouch.x;
      let deltaY = touch.pageY - this.defaultTouch.y;
      let deltaTime = event.timeStamp - this.defaultTouch.time;
      if (deltaTime < 500) {
        if (Math.abs(deltaX) > 60) {
          if (deltaX > 0) {
            //console.log('right');
            this.game.move(3).subscribe((mergeScore: number) => {
              moveSuccessful = moveSuccessful || this.score < mergeScore;
            }, console.error, () => {
              if (this.gameOver) return;
              if (moveSuccessful) this.game.randomize();
              this.score = this.game.score;
              this.gameOver = this.game.isGameOver;
            });
          } else {
            //console.log('left');
            this.game.move(2).subscribe((mergeScore: number) => {
              moveSuccessful = moveSuccessful || this.score < mergeScore;
            }, console.error, () => {
              if (this.gameOver) return;
              if (moveSuccessful) this.game.randomize();
              this.score = this.game.score;
              this.gameOver = this.game.isGameOver;
            });
          }
        }

        if (Math.abs(deltaY) > 60) {
          if (deltaY > 0) {
            //console.log('down');
            this.game.move(1).subscribe((mergeScore: number) => {
              moveSuccessful = moveSuccessful || this.score < mergeScore;
            }, console.error, () => {
              if (this.gameOver) return;
              if (moveSuccessful) this.game.randomize();
              this.score = this.game.score;
              this.gameOver = this.game.isGameOver;
            });
          } else {
            //console.log('up');
            this.game.move(0).subscribe((mergeScore: number) => {
              moveSuccessful = moveSuccessful || this.score < mergeScore;
            }, console.error, () => {
              if (this.gameOver) return;
              if (moveSuccessful) this.game.randomize();
              this.score = this.game.score;
              this.gameOver = this.game.isGameOver;
            });
          }
        }
      }
    }
  }

  constructor(private game: GameService) {
    this.initGame();
   }

  ngOnInit(): void {
  }

  initGame() {
    this.cells = this.game.cells;
    this.gameOver = this.completed = false;
    this.game.randomize();
    this.game.randomize();
    this.cells.map(cell => cell.success.subscribe(this.successHandler));
  }

  restart() {
    this.score = 0;
    this.game.restart();
    this.initGame();
  }

  successHandler = () => {
    this.completed = true;
    this.gameOver = true;
  }

}
