import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BoardComponent } from './board/board.component';
import { CellComponent } from './cell/cell.component';
import { GameService } from './game.service';
import { TwentyfourtyeightComponent } from './twentyfourtyeight/twentyfourtyeight.component';
import {MatCardModule} from '@angular/material/card';

@NgModule({
  declarations: [BoardComponent, CellComponent, TwentyfourtyeightComponent],
  imports: [
    CommonModule,
    MatCardModule
  ],
  exports: [CellComponent, BoardComponent, TwentyfourtyeightComponent],
  providers: [GameService]
})
export class TwentyFourtyEightModule { }
